<template>
  <div class="vap-page">
    <div class="vap-page-header">
      <h1>{{ $formsLabel }} Edit/Replace</h1>
    </div>
    <div class="vap-page-content">
      <div class="row">
        <VasionDropList
          v-slot="slotItem"
          v-model="selectedAttributeForm"
          class="row-item"
          :dataArray="attributeFormList"
          name="attribute-form-list"
          :title="`${$formsLabel.toUpperCase()}`"
          type="plain-list"
          valueName="value"
          width="392"
          displayName="name"
          :required="true"
        >
          {{ slotItem.item.name }}
        </VasionDropList>
        <VasionDropList
          v-slot="slotItem"
          v-model="selectedAttributeField"
          class="row-item"
          name="attribute-field-list"
          :dataArray="attributeFieldList"
          width="392"
          :title="`${$formsLabel.toUpperCase()} FIELDS`"
          placeholder="Select a field..."
          type="plain-list"
          displayName="name"
          valueName="value"
          :required="true"
        >
          {{ slotItem.item.name }}
        </VasionDropList>
      </div>
      <div class="row">
        <VasionInput
          v-model="searchForInput"
          class="row-item"
          inputType="top-white"
          width="392"
          title="SEARCH FOR"
        />
        <VasionInput
          v-model="replaceWithInput"
          class="row-item"
          inputType="top-white"
          width="392"
          title="REPLACE WITH"
        />
      </div>
      <VasionButton
        id="replace-button"
        name="replace-button"
        classProp="primary"
        class="button"
        @vasionButtonClicked="replaceClick()"
      >
        REPLACE
      </VasionButton>
    </div>
    <VasionSnackbar
      id="workflow-started-snack"
      :showSnackbarBool.sync="showSnackbarBool"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />
    <VasionGeneralModal
      id="confirm-replace-dialog"
      :hideButtons="true"
      :modalType="'slot'"
      buttonGroup="single"
      :sync="showConfirmDialog"
      @noButtonClick="showConfirmDialog = false"
    >
      <VasionConfirmationDialog
        message="Are you sure you want to run find and replace?"
        @noButtonClick="showConfirmDialog = false"
        @yesButtonClick="replace(); showConfirmDialog = false"
      />
    </VasionGeneralModal>
  </div>
</template>

<script>
import VasionSnackbar from '@/components/shared/VasionSnackbar.vue';

export default {
  name: 'AdminObjectEditAndReplace',
  components: {
    VasionSnackbar,
  },
  data: function () {
    return {
      replaceWithInput: '',
      searchForInput: '',
      selectedAttributeField: { empty: true },
      selectedAttributeForm: { empty: true },
      showConfirmDialog: false,
      showSnackbarBool: false,
      snackbarImage: false,
      snackbarSubTitle: '',
      snackbarTitle: '',
    }
  },
  computed: {
    attributeFieldList() { return this.$store.state.common.indexFields },
    attributeFormList() {
      return this.$store.state.attributeForm.forms.map((f) => {
        return {
          name: f.name,
          value: f.value,
        }
      })
    },
  },
  watch: {
    selectedAttributeForm: function () {
      if (this.selectedAttributeForm && this.selectedAttributeForm.value > 0) {
        this.$store.dispatch('common/getIndexFieldsForForm', this.selectedAttributeForm.value)
      }
    },
  },
  created() {
    this.$store.dispatch('attributeForm/getForms')
  },
  methods: {
    async replace() {
      const payload = {
        indexFieldName: this.selectedAttributeField.value,
        indexFormID: this.selectedAttributeForm.value,
        newValue: this.replaceWithInput,
        oldValue: this.searchForInput,
        userID: this.$store.state.common.userID,
        replaceAsync: true,
      }
      const result = await this.$store.dispatch('admin/findAndReplaceIndexData', payload)
      if (result.data.Value) {
        this.snackbarImage = true
        this.snackbarTitle = 'SUCCESS'
        this.snackbarSubTitle = 'Find and Replace has started. You will receive an email when complete.'
        this.showSnackbarBool = true
      } else {
        this.snackbarImage = false
        this.snackbarTitle = 'ERROR'
        this.snackbarSubTitle = `The selected ${this.formsLabel} Field is not used in a form.`
        this.showSnackbarBool = true
      }
    },
    replaceClick() {
      if (this.selectedAttributeForm.empty || this.selectedAttributeField.empty) {
        this.snackbarImage = false
        this.snackbarTitle = 'Missing Value(s)'
        this.snackbarSubTitle = `Please select an ${$formsLabel} and an ${$formsLabel} Field`
        this.showSnackbarBool = true
        return
      } else if (!this.replaceWithInput && !this.searchForInput) {
        this.snackbarImage = false
        this.snackbarTitle = 'Missing Value(s)'
        this.snackbarSubTitle = 'Please fill out Search For or Replace With.'
        this.showSnackbarBool = true
        return
      }
      this.showConfirmDialog = true
    },
  },
}
</script>

<style lang="scss" scoped>
  .title {
    margin-top: 22px;
    margin-bottom: 22px;
  }
  .row {
    display: flex;
    flex-direction: row;
    margin: 0px;
    margin-bottom: 16px;
  }
  .inner-div {
    margin: 10px 16px 10px 16px;
  }
  .row-item {
    margin-right: 15px;
  }
  .button {
    margin-left: -8px;
  }
</style>
